<template>
  <div class="image-manage">
    <el-dialog title="查看图片" :close-on-click-modal="false" :visible.sync="visible" width="670px" center>
      <div class="imgBox"><img :src="row.url" alt=""></div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="visible = false">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data () {
    return {
      visible: false,
      row: {}
    }
  },
  methods: {
    init (row) {
      this.visible = true
      this.row = row
    }
  }
}
</script>

<style lang="less">
.image-manage {
  .el-dialog {
    margin-top: 5vh !important
  }
  .el-dialog__body {
    max-height: 450px;
    overflow: auto;
    padding: 10px 25px; 
  }
  .el-dialog__footer {
    text-align: right
  }
}
.el-message-box.bigImg {
    width: auto;
    .imgBox {
      max-height: 500px;
      overflow: auto
    }
}
.popImg {
  width: 300px;
  height: 300px;
  img{
    width: 100%;
    height: 100%;
  }
}
</style>
